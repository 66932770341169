@import 'styles/partials/colors';

@mixin ripple($variant) {
  position: relative;
  overflow: hidden;
  // transform: translate3d(0, 0, 0);

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(circle, $variant 10%, transparent 10.01%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(10, 10);
    opacity: 0;
    transition: transform 0.5s, opacity 1s;
  }

  &:active:after {
    transform: scale(0, 0);
    opacity: 0.3;
    transition: 0s;
  }
}

@mixin primary-ripple {
  @include ripple($primary-accent);
}

@mixin secondary-ripple {
  @include ripple($secondary-accent);
}

.ripple, .primary-ripple {
  @include primary-ripple();
}

.secondary-ripple {
  @include secondary-ripple();
}
