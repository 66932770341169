$primary: #055f7c;

$primary-light: #377f96;
$primary-lighter: #6ad2e4;

$primary-darker: #044c63;
$primary-dark: #085069;

$primary-accent: #085069;

$base-bg: #fafafa;
$base-bg-dark: #e8e8e8;
$base-bg-darker: #b8b8b8;

$text-bg: $primary-dark;
$text-bg-dark: $primary-dark;
$text-bg-darker: $primary-dark;

$text-primary: $base-bg-darker;
$text-primary-dark: $base-bg;
$text-primary-darker: $base-bg;

$secondary: #ffafccff;
$secondary-accent: #ff5895;

$error: #ff3953;
$error-bg: lighten($error, 30%);
$success: rgb(57, 149, 57);
$info: #6464f6;
$warning: orange;
$danger: darken($error, 20);

// Needed for access to the variables in the JS
:root {
    --primary-light: #377f96;
    --primary-lighter: #6ad2e4;
    --primary-darker: #044c63;
    --primary-dark: #085069;
}
