@mixin hover-underline-animation($variant) {
  display: inline-block;
  position: relative;
  color: $variant;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: $variant;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  &:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
}

@mixin underline($variant) {
  display: inline-block;
  position: relative;
  color: $variant;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: $variant;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
}

@mixin active-underline {
  &::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
}

.width-transition {
  transition: width 200ms ease-in-out;
}
